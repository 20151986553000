export default function (Component) {
      Component.options.frontmatter = {
  "published": "2018-11-20T00:00:00.000Z",
  "tags": [
    "vue",
    "typescript",
    "dx"
  ],
  "description": "A practical guide for type checking vuex modules written in JS and getting things done.",
  "title": "Type Vuex without TypeScript"
}
    }